/* Import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&display=swap');

body {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; /* stylelint-disable-line */
  text-size-adjust: none; /* stylelint-disable-line */
}
