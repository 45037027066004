/* Load fonts */
@import 'typography';
@import 'variables';

html,
body {
  background-color: $ui-grey;
  font-family: 'Work Sans', sans-serif;
}

hr {
  padding: 0;
  border: none;
  border-bottom: 1px solid lightgray;
  margin: 0;
}
